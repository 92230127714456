.text-center {
  text-align: center !important;
}

.p-1 {
  padding-top: 10px;
}
.p-2 {
  padding-top: 50px;
}

.p-3 {
  padding-top: 100px;
}
.p-4 {
  padding-top: 150px;
}
.p-5 {
  padding-top: 200px;
}

.desktop {
  display: block !important;
}

.mobile {
  display: none !important;
}

.btn-submit {
  background: var(--ion-color-primary, #3880ff);
  color: var(--ion-color-primary-contrast, #fff);
  width: 100%;
  height: 35px;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11pt;
}

.btn-submit:disabled,
.btn-submit[disabled] {
  opacity: 0.5;
}

@media (prefers-color-scheme: light) {
  .dark {
    display: none !important;
  }
  .light {
    display: block !important;
  }
}

@media (prefers-color-scheme: dark) {
  .dark {
    display: block !important;
  }
  .light {
    display: none !important;
  }
}

@media only screen and (max-width: 992px) {
  .desktop {
    display: none !important;
  }

  .mobile {
    display: block !important;
  }
}

.desktop .reading-label {
  font-size: 14px !important;
}
